<template>
  <div>
    <TTableAdvance
      :items="delivery_partners"
      :fields="fields"
      store="warehouse.delivery_partners"
      @click-clear-filter="clearFilter"
      resource="/management/agency"
      creatable
      deletable
      @click-create="showModalCreate = true"
    >
      <template #append-actions="{ item }">
        <CCol class="px-1 text-center" col="12">
          <TButtonEdit @click="editItem(item)" />
        </CCol>
      </template>
      <template #name="{ item }">
        <td>
          <TMessage :content="item.name" noTranslate />
        </td>
      </template>
      <template #tel="{ item }">
        <td>
          <TMessage
            v-if="item.place_of_delivery"
            :content="item.place_of_delivery.tel"
            noTranslate
          />
        </td>
      </template>
      <template #address="{ item }">
        <td>
          <TMessage
            v-if="item.place_of_delivery"
            :content="item.place_of_delivery.full_address"
            noTranslate
          />
        </td>
      </template>
      <template #note="{ item }">
        <td>
          <TMessage
            v-if="item.place_of_delivery"
            :content="item.place_of_delivery.note"
            noTranslate
          />
        </td>
      </template>
      <template #created_at="{ item }">
        <td>
          <TMessageDateTime :content="item.created_at" small />
        </td>
      </template>
      <template #id-filter>
        <TInputText
          placeholder="Id"
          :value.sync="filter.id"
          @update:value="filterChange"
        />
      </template>
      <template #name-filter>
        <TInputText
          placeholder="Name"
          :value.sync="filter.name"
          @update:value="filterChange"
        />
      </template>
    </TTableAdvance>
    <DeliveryPartnersModal
      :show.sync="showModalCreate"
      @created="filterChange"
    />
    <DeliveryPartnersModal
      :show.sync="showModalEdit"
      :partner="detail"
      @updated="filterChange"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DeliveryPartnersModal from "./components/DeliveryPartnersModal.vue";

export default {
  components: { DeliveryPartnersModal },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px; min-width: 40px" },
        {
          key: "id",
          label: "Id",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "name",
          label: "Partner",
          _style: "width: 300px; min-width: 300px",
        },
        { key: "tel", label: "Tel", _style: "width: 150px; min-width: 150px" },
        {
          key: "address",
          label: "Address",
          _style: "width: 250px; min-width: 250px",
        },
        {
          key: "note",
          label: "Note",
          _style: "width: 250px; min-width: 250px",
        },
      ],
      showModalCreate: false,
      showModalEdit: false,
      filter: {},
    };
  },
  computed: {
    ...mapGetters({
      delivery_partners: "warehouse.delivery_partners.list",
      detail: "warehouse.delivery_partners.detail",
    }),
  },
  methods: {
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterFields = this.lodash.pickBy(filter);
      this.$store.dispatch(
        "warehouse.delivery_partners.apply-query",
        filterFields
      );
    },
    clearFilter() {
      this.filter = {};
      this.filterChange();
    },
    editItem(item) {
      this.$store.commit("warehouse.delivery_partners.select", item.id);
      this.showModalEdit = true;
    },
  },
};
</script>
