<template>
  <TModal
    :title="!is_edit ? 'Create' : 'Edit'"
    :show="show"
    @update:show="$emit('update:show', $event)"
  >
    <template #actions>
      <TButtonCreate
        :options="{ disabled: loading || !input_data.ward_id }"
        variant="outline"
        @click="create"
        v-if="!is_edit && show"
      />
      <TButtonSave
        variant="outline"
        @click="update"
        v-if="is_edit && show"
        :options="{ disabled: loading || !input_data.ward_id }"
      />
    </template>
    <TInputText
      label="Name"
      class="mb-3"
      :value="input_data.name"
      @update:value="input_data.name = $event"
    />
    <TInputText class="mb-3" label="Tel" :value.sync="input_data.tel" />
    <hr />
    <SSelectAddress
      v-if="show"
      :ward_id.sync="input_data.ward_id"
      :address_detail.sync="input_data.address"
      class="mb-3"
    />
    <TInputText label="Note" :value.sync="input_data.note" />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    partner: {
      type: Object,
      required: false,
    },
  },
  inputDefault: {
    name: null,
    consignee: null,
    tel: null,
    note: null,
    ward_id: null,
    address: null,
  },
  data() {
    return {
      input_data: this.getInput(),
      loading: false,
    };
  },
  watch: {
    show: {
      handler() {
        if (this.show & this.is_edit) {
          this.input_data = this.getInput();
        }
      },
    },
  },
  computed: {
    is_edit() {
      if (!this.partner) {
        return false;
      }
      return !!this.partner.id;
    },
    input: {
      get: function () {
        return this.getInput();
      },
      set: function (newValue) {
        this.input_data = newValue;
      },
    },
    data_submit: {
      cache: false,
      get() {
        return {
          ...this.input_data,
        };
      },
    },
  },
  methods: {
    create() {
      this.loading = true;
      this.input_data.consignee = this.input_data.name;
      const place_of_delivery = this.lodash.mapKeys(
        this.lodash.omit(this.data_submit, ["name"]),
        (value, key) => {
          return `place_of_delivery[${key}]`;
        }
      );
      this.$store
        .dispatch("warehouse.delivery_partners.create", {
          ...this.lodash.pickBy(
            {
              name: this.input_data.name,
              ...place_of_delivery,
            },
            this.lodash.identity
          ),
        })
        .then((data) => {
          this.clearForm();
          this.$emit("created", data);
          this.$emit("update:show", false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.loading = true;
      const promiseAll = [];
      if (
        !this.lodash.isEqual(
          this.lodash.omit(this.data_submit, "name"),
          this.lodash.omit(this.input, "name")
        )
      ) {
        promiseAll.push(
          this.$tomoni.warehouse.place_of_deliveries.update(
            this.partner.place_of_delivery_id,
            this.lodash.omit(this.data_submit, ["name"])
          )
        );
      }
      if (
        !this.lodash.isEqual(
          this.lodash.pick(this.data_submit, "name"),
          this.lodash.pick(this.input, "name")
        )
      ) {
        promiseAll.push(
          this.$store.dispatch("warehouse.delivery_partners.update", {
            id: this.partner.id,
            attributes: {
              name: this.data_submit?.name,
            },
          })
        );
      }
      Promise.all(promiseAll)
        .then(() => {
          this.$emit("updated");
          this.$emit("update:show", false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getInput() {
      if (!this.is_edit) {
        return this.inputDefault();
      }
      return {
        name: this.partner.name,
        ...this.lodash.pick(this.partner.place_of_delivery, [
          "ward_id",
          "address",
          "tel",
          "note",
        ]),
      };
    },
    inputDefault() {
      return { ...this.$options.inputDefault };
    },
    clearForm() {
      this.input = this.inputDefault();
    },
  },
};
</script>
